/* global app, loginForgetBox */

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import $ from 'jquery';
//import './modules/neoxiel.js';
//import md5 from 'js-md5';
import CryptoJS from 'crypto-js';

Login = new Login().init();
window.CryptoJS = CryptoJS;

function Login(){

    
    var droit;
    /**
     * Initialisation de la page permettant de se logger
     * @returns {void}
     */
    this.init = function () {
        getParameter();
        $("#email").off("change").on("change",function(){emailValidator();} );
        $("#password").off("change").on("change",function(){passwordValidator();} );

        $("#forget").off("click").on("click",function(){loginForgetBox.show();});
        $("#connexion").off("click").on("click",function(){buttonInit();}); 
        
        $(document).on('keypress',function(e) {if(e.which === 13) {$("#connexion").trigger("click");}});
    };
    
    /**
     * Permet en fonction du parametre passer par l'url d'afficher la liste des 
     * utilisateurs ou administrateurs en modifiant le input par un select. 
     * @returns {void}
     */
    var getParameter = function () {
        var search = window.location.search;
        switch (search) {
            case "?true": getUsers(70);break;
            case "?admin": getUsers(90);break;
            default :if(!unlock(search)){getUsers();}break;
        };
    };
    
    /**
     * Essentiellement utilisé en developpement 
     * Permet de préremplir le formulaire d'identification
     * @param {type} pSearch
     * @returns {Boolean}
     */
    var unlock = function(pSearch){
        var parameters = pSearch.split("=");
        if(parameters[0] === "?mail"){
            var login = parameters[1].split(":");
            $("#email").val(login[0]);
            $("#password").val(login[1]);
            return true;
        }else{return false;}
    };
    
    /**
     * Permet de modifier le input par un select et d'afficher la listes des 
     * utilisateurs en fonction des droit passé en paramètre 
     * @param {Integer} pDroit
     * @returns {void}
     */
    var getUsers = function(pDroit) {
        droit = pDroit;
        var html = `<option value="" >Selectionner un utilisateur...</option>`;
        if(droit > 0){
            $('#email').replaceWith('<select id="email" class="form-control" tabindex="1" required autofocus></select>');
            $.ajax({
                url:"./pages/login/process/users.php",
                cache:false,
                type:"POST",
                dataType:"JSON",
                data:{droit:droit},
                error:function (jqXHR, textStatus, errorThrown) {error();},
                beforeSend: function (xhr) {$(`select#email`).empty();},
                success:function (data, textStatus, jqXHR) {
                data.records.forEach(function (v,i) {html+= `<option value="${v.value}">${v.text}</option>`;});},
                complete:function (jqXHR, textStatus) {$(`select#email`).html(html);}
            }).then(function(){});            
        }
    };
    
    /**
     * Dans le cas ou l'utilisateur n'a pas été reconnu affichage d'une fenêtre
     * invitant l'utilisateur a tenter à nouveau de se connecter  
     * @returns {undefined}
     */
    var error = function () {};
    
    
    var isValid = function () {
        if(!emailValidator())return false;
        if(!passwordValidator())return false;
        return true;
    };
   
   var emailValidator = function () {
        if($("#email").val().length === 0) {$(".invalid-feedback:eq(0)").show();return false;}
        else{$(".invalid-feedback:eq(0)").hide();return true;}
    };
   
    var passwordValidator = function () {
        if($("#password").val().length === 0) {$(".invalid-feedback:eq(1)").show();return false;}
        else{$(".invalid-feedback:eq(1)").hide();return true;}
    };
    
    var buttonInit = function () {
        if(!isValid())return;
        $.ajax({
            url:"./pages/login/process/select.php",
            cache:false,
            type:"POST",
            dataType:"JSON",
            data:{
                email: CryptoJS.SHA1($("#email").val()).toString(CryptoJS.enc.Hex),
                password:CryptoJS.SHA1($("#password").val()).toString(CryptoJS.enc.Hex),
                droit:droit
            },
            error:function (jqXHR, textStatus, errorThrown) {error();},
            beforeSend: function (xhr) {},
            success:function (data, textStatus, jqXHR) {
                if(data.status === "success"){$.redirectPost(data.record.url,{token: data.record.token});}
                else{
                    $("#warning").show();
                    setTimeout(function(){$("#warning").hide();},3000);
                }   
            ;},
            complete:function (jqXHR, textStatus) {}
        }).then(function(){});
    };
    return this;
}

/**
 * Permet de faire une redirection vers une autre page en passant des variables
 * en méthode POST. evite l'utilisation du plugin : mgalante/jquery.redirect qui
 * plante au chargement de jquery  
 */
$.extend({
  redirectPost: function(location, args) {
    var form = '';
    $.each(args, function(key, value) {
      form += '<input type="hidden" name="' + key + '" value="' + value + '">';
    });
    $('<form action="' + location + '" method="POST">' + form + '</form>').appendTo($(document.body)).submit();
  }
});